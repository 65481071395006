<template>
    <PopupReiniciarTerminal>
        <div class="header-content2">
            <div class="close-style2">
                <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
                    src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
            </div>


            <div>
                <h1 style="
              text-align: center;
              margin-top: 1rem;
              margin-bottom: 3rem;
              color: white;
              font-weight: 100;
            ">
                    {{ $t("message.planta.f45") }}
                </h1>
            </div>
        </div>

        <div class="group">
            <h3 style="color: white; line-height: 25px">
                {{ terminalSelected.terminals_nickname.toUpperCase() }}
            </h3>
        </div>

        <div class="texto-alerta">
            <p style="
            color: #ffff;
            line-height: 23px;
            text-align: justify;
            font-size: 1.4rem;
          ">
                {{ $t("message.planta.b17") }}
            </p>
        </div>

        <div class="btn-content2" style="display: flex; justify-content: space-evenly">
            <button class="btn-baja" @click="reiniciarTerminal" type="submit" id="btn-cerrar-popup2">
                <strong>{{ $t("message.planta.op30") }} </strong>
            </button>
        </div>
    </PopupReiniciarTerminal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import PopupReiniciarTerminal from "@/components/planta-conte/PopupReiniciarTerminal.vue";



export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close"],
    components: {
        PopupReiniciarTerminal,
    },
    computed: {
        ...mapState({
            user: (state: any) => state.AUTH.user,
            token: (state: any) => state.AUTH.token,
        }),
        ...mapGetters(["permissions"]),
    },
    data() {
        return {
            reactivateReason: "",
            messageTraduct: ""
        };
    },
    mounted() {
        console.log("Prop terminalSelected en mounted:", this.terminalSelected);
    },

    methods: {
        closePopup() {
            this.$emit("close");           
        },

        reiniciarTerminal() {
                // Log para revisar el contenido de terminalSelected
                console.log("Objeto terminalSelected recibido:", this.terminalSelected);

                // Validar campos requeridos
                const requiredFields = ["accountNumber", "userTerminalId", "kitSerialNumber"];
                const missingFields = requiredFields.filter((field) => !this.terminalSelected[field]);

                if (missingFields.length > 0) {
                    store.dispatch("showToast", {
                        message: `Por favor, asegúrate de que los siguientes datos estén presentes: ${missingFields.join(", ")}`,
                        type: "error",
                    });
                    return;
                }

                const payload = {
                    accountNumber: this.terminalSelected.accountNumber,
                    deviceId: this.terminalSelected.id, 
                    kitSerialNumber: this.terminalSelected.kitSerialNumber,
                };

                console.log("Datos que se están enviando a la API para reiniciar el terminal:", payload);

                store.dispatch("RESETTERMINAL", payload)
                    .then((response) => {
                        store.dispatch("showToast", {
                            message: "Terminal reiniciado exitosamente.",
                            type: "success",
                        });
                        this.closePopup();
                    })
                    .catch((error) => {
                        console.error("Error en la respuesta:", error);
                        store.dispatch("showToast", {
                            message: "Error al reiniciar el terminal. Inténtalo nuevamente.",
                            type: "error",
                        });
                    });
            },
    },

    // reiniciarTerminal() {
    //     if (!this.terminalSelected.accountNumber || !this.terminalSelected.deviceId || !this.terminalSelected.kitSerialNumber) {
    //         store.dispatch("showToast", {
    //             message: "Por favor, asegúrate de que todos los datos requeridos estén presentes.",
    //             type: "error"
    //         });
    //         return;
    //     }

    //     const payload = {
    //         accountNumber: this.terminalSelected.accountNumber,
    //         deviceId: this.terminalSelected.deviceId,
    //         kitSerialNumber: this.terminalSelected.kitSerialNumber,
    //     };

    //     console.log("Datos que se están enviando a la API para reiniciar el terminal:", payload);

    //     store.dispatch("RESETTERMINAL", payload)
    //         .then((response) => {
    //             store.dispatch("showToast", {
    //                 message: "Terminal reiniciado exitosamente.",
    //                 type: "success"
    //             });
    //             this.closePopup();
    //         })
    //         .catch((error) => {
    //             console.error("Error en la respuesta:", error);
    //             store.dispatch("showToast", {
    //                 message: "Error al reiniciar el terminal. Inténtalo nuevamente.",
    //                 type: "error"
    //             });
    //         });
    // }


});
</script>
<style lang="scss">
.dropdown-content {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: 0px;
}

.dropdown-content:last-of-type {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: -225px;
    margin-left: 45px;
}

.dropdown-content:hover {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
}

.dropdown-content a:hover {
    background-color: #0079ff;
}

.btn-submit-address {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.btn-submit-mapa {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
    background-color: white;
}

.custom-input {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 95%;
    cursor: pointer;
}

.header-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.poppup-body p {
    color: #ffffff;
}

.btn-content2 button {
    height: 40px;
    cursor: pointer;
    width: 9rem;
    border-radius: 9px;
    background-color: #007bff;  color: #ffffff;
}

.close-style2 {
    display: flex;
    justify-content: end;
}
</style>