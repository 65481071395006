import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/telefon.png'
import _imports_1 from '@/assets/banderas/en.png'
import _imports_2 from '@/assets/banderas/es.png'
import _imports_3 from '@/assets/banderas/brasil.png'
import _imports_4 from '@/assets/menu.png'
import _imports_5 from '@/assets/checkbox-multipe.png'
import _imports_6 from '@/assets/note-edit-outline.png'


const _hoisted_1 = { class: "nav-1" }
const _hoisted_2 = { class: "conte-nav" }
const _hoisted_3 = { class: "title1" }
const _hoisted_4 = {
  style: {"z-index":"1"},
  class: "idioma"
}
const _hoisted_5 = {
  key: 0,
  class: "bandera",
  src: _imports_1,
  alt: "en-bandera"
}
const _hoisted_6 = {
  key: 1,
  class: "bandera",
  src: _imports_2,
  alt: "es-bandera"
}
const _hoisted_7 = {
  key: 2,
  class: "bandera",
  src: _imports_3,
  alt: "es-bandera"
}
const _hoisted_8 = {
  key: 0,
  style: {"z-index":"2"},
  class: "dropdown-menu"
}
const _hoisted_9 = { class: "dropdown-content-menu" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 0,
  class: "user-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterLink, {
          style: {"z-index":"2!important"},
          to: "/home"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              class: "img-fluid",
              src: _imports_0,
              alt: "logo-servicios-leo"
            }, null, -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('message.title')), 1),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.$i18n.locale === 'en')
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.$i18n.locale === 'es')
            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
            : _createCommentVNode("", true),
          (_ctx.$i18n.locale === 'pt')
            ? (_openBlock(), _createElementBlock("img", _hoisted_7))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLocaleObserve(_ctx.$i18n.locale)))
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("option", { value: "en" }, "EN", -1),
            _createElementVNode("option", { value: "es" }, "ES", -1),
            _createElementVNode("option", { value: "pt" }, "PT", -1)
          ]), 544), [
            [_vModelSelect, _ctx.$i18n.locale]
          ])
        ]),
        (_ctx.user)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[11] || (_cache[11] = _createElementVNode("button", { class: "btn-burguer" }, [
                _createElementVNode("img", {
                  style: {"margin-top":"3px","height":"22px","width":"22px","cursor":"pointer"},
                  src: _imports_4,
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.redirecction && _ctx.permissions.some((evt) => evt.id === 10 || evt.id === 1))
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: _ctx.redirecction,
                      target: "_blank",
                      rel: "noopener noreferrer"
                    }, [
                      _cache[7] || (_cache[7] = _createElementVNode("img", {
                        src: _imports_5,
                        alt: "editar-perfil"
                      }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.auth.f5')), 1)
                    ], 8, _hoisted_10))
                  : _createCommentVNode("", true),
                (_ctx.permissions.some((evt) => evt.id === 168))
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.AtencionPedido()), ["prevent"])),
                      href: "#"
                    }, [
                      _cache[8] || (_cache[8] = _createElementVNode("img", {
                        src: _imports_6,
                        alt: "Atencion"
                      }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.auth.F9')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.permissions.some((evt) => evt.id === 171))
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 2,
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.reportKPI()), ["prevent"])),
                      href: "#"
                    }, [
                      _cache[9] || (_cache[9] = _createElementVNode("img", {
                        src: _imports_6,
                        alt: "Atencion"
                      }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.auth.F10')), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("a", {
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.logout()), ["prevent"])),
                  href: "#"
                }, [
                  _cache[10] || (_cache[10] = _createElementVNode("img", {
                    src: _imports_6,
                    alt: "cerrar-session"
                  }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.auth.logout')), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("strong", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.user.fullname), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isModalVisible)
      ? (_openBlock(), _createBlock(_component_ModalComponent, {
          key: 0,
          fileData: _ctx.fileData,
          onConfirm: _ctx.handleConfirmDownload,
          onCancel: _ctx.handleCancelDownload
        }, null, 8, ["fileData", "onConfirm", "onCancel"]))
      : _createCommentVNode("", true)
  ], 64))
}