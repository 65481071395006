<template>
    <div>
        <div class="modal">
            <div class="modal-content">
                <h3>Confirmación de descarga</h3>
                <p>¿Deseas descargar el archivo: {{ fileData.name }}?</p>
                <button @click="confirm" class="btn-confirm">Sí</button>
                <button @click="cancel" class="btn-cancel">No</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fileData: {
            type: Object,
            required: true,
        },
    },
    methods: {
        confirm() {
            this.$emit("confirm");
        },
        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>

<style>
/* Estilo del fondo del modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Fondo semitransparente */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Contenedor del contenido del modal */
.modal-content {
    background: rgba(30, 30, 60, 0.9);
    /* Color que se asemeja al fondo del espacio */
    color: white;
    /* Texto blanco para contraste */
    padding: 30px;
    /* Espaciado interno */
    border-radius: 15px;
    /* Bordes redondeados */
    text-align: center;
    /* Centrado del texto */
    width: 500px;
    /* Ancho ajustado */
    max-width: 90%;
    /* Se adapta a pantallas pequeñas */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* Sombra para darle profundidad */
}

/* Título del modal */
.modal-content h3 {
    margin: 0 0 15px;
    font-size: 24px;
    color: #ffcc00;
    /* Color destacado para el título */
}

/* Texto del modal */
.modal-content p {
    font-size: 18px;
    color: #f0f0f0;
    /* Texto de color claro */
    margin-bottom: 20px;
}

/* Botón de confirmar */
.btn-confirm {
    background-color: #28a745;
    /* Verde */
    color: white;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btn-confirm:hover {
    background-color: #218838;
}

/* Botón de cancelar */
.btn-cancel {
    background-color: #dc3545;
    /* Rojo */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btn-cancel:hover {
    background-color: #c82333;
}
</style>