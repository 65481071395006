import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content2" }
const _hoisted_2 = { class: "close-style2" }
const _hoisted_3 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_4 = { class: "texto-alerta" }
const _hoisted_5 = { style: {"color":"#ffff","line-height":"23px","text-align":"justify","font-size":"1.4rem"} }
const _hoisted_6 = { class: "group" }
const _hoisted_7 = {
  for: "reason",
  style: {"color":"white","text-align":"start"}
}
const _hoisted_8 = { class: "popup-form" }
const _hoisted_9 = { class: "group" }
const _hoisted_10 = { class: "group" }
const _hoisted_11 = { class: "reason-input" }
const _hoisted_12 = {
  class: "btn-content2",
  style: {"display":"flex","justify-content":"space-evenly"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_PopupAgregarEquipo = _resolveComponent("PopupAgregarEquipo")!

  return (_openBlock(), _createBlock(_component_PopupAgregarEquipo, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
            style: {"cursor":"pointer","margin-top":"-1rem"},
            width: "25",
            height: "25",
            src: _imports_0,
            alt: "delete-sign--v1"
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("message.home.btn5")), 1)
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "group" }, [
        _createElementVNode("h3", { style: {"color":"white","line-height":"25px"} })
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("message.home.m02")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("message.planta.f36")), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("form", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_v_select, {
              modelValue: _ctx.principalId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.principalId) = $event)),
              options: _ctx.principal,
              label: "name",
              required: "",
              placeholder: _ctx.$t('message.planta.f31'),
              class: "custom-select"
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_v_select, {
              modelValue: _ctx.accountSelected,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.accountSelected) = $event)),
              options: _ctx.accounts,
              label: "countryName",
              required: "",
              placeholder: "country",
              class: "custom-select"
            }, null, 8, ["modelValue", "options"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "reason",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.agregarReason) = $event)),
          placeholder: "KIT SERIAL NUMBER",
          style: {"width":"100%","padding":"0.5rem","margin-top":"0.5rem"}
        }, null, 512), [
          [_vModelText, _ctx.agregarReason]
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("button", {
          class: "btn-baja",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.agregarEquipo && _ctx.agregarEquipo(...args))),
          type: "submit",
          id: "btn-cerrar-popup2"
        }, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f38")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}