<template>
    <PopupActivacionBypass>
        <div class="header-content2">
            <div class="close-style2">
                <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
                    src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
            </div>


            <div>
                <h1 style="
              text-align: center;
              margin-top: 1rem;
              margin-bottom: 3rem;
              color: white;
              font-weight: 100;
            ">
                    {{ $t("message.planta.f44") }}
                </h1>
            </div>
        </div>

        <div class="group">
            <h3 style="color: white; line-height: 25px">
                {{ terminalSelected.terminals_nickname.toUpperCase() }}
            </h3>
        </div>

        <div class="texto-alerta">
            <p style="
            color: #ffff;
            line-height: 23px;
            text-align: justify;
            font-size: 1.4rem;
          ">
                {{ $t("message.planta.b07") }}
            </p>
        </div>

        <div class="btn-content2" style="display: flex; justify-content: space-evenly">
            <button class="btn-baja" @click="activacionBypass" type="submit" id="btn-cerrar-popup2">
                <strong>{{ $t("message.planta.op10") }} </strong>
            </button>
        </div>
    </PopupActivacionBypass>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import PopupActivacionBypass from "@/components/planta-conte/PopupActivacionBypass.vue";



export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close"],
    components: {
        PopupActivacionBypass,
    },
    computed: {
        ...mapState({
            user: (state: any) => state.AUTH.user,
            token: (state: any) => state.AUTH.token,
        }),
        ...mapGetters(["permissions"]),
    },
    data() {
        return {
            reactivateReason: "",
            messageTraduct: ""
        };
    },
    mounted() { },
    methods: {
        closePopup() {
            this.$emit("close");
        },

        // activacionBypass() {
        //     // console.log("test222", this.terminalSelected)

        //     const payload = {
        //         deviceId: Number(this.terminalSelected.id),
        //         statusTechnic: String(this.terminalSelected.terminals_statusTechnic),
        //         principal: String(this.terminalSelected.principalName),
        //         kitSerialNumber: String(this.terminalSelected.kitSerialNumber),
        //         siteId: String(this.terminalSelected.terminals_nickname),
        //     };

        //     console.log("Datos que se están enviando a la API para activar bypass:", payload);
        //     store.dispatch("MODEBYPASS", payload)
        //         .then((response) => {
        //             console.log("Respuesta del servidor:", response);

        //             const successMessage = this.$t("message.planta.op10");

        //             store.dispatch("showToast", {
        //                 message: successMessage,
        //                 type: "success",
        //             });
        //             this.closePopup();
        //         })
        //         .catch((error) => {
        //             console.error("Error en la respuesta:", error);
        //             store.dispatch("showToast", {
        //                 message: "Error al activar el modo bypass. Inténtalo nuevamente.",
        //                 type: "error",
        //             });
        //         });
        // }

        activacionBypass() {
            const payload = {
                deviceId: Number(this.terminalSelected.id),
                statusTechnic: String(this.terminalSelected.terminals_statusTechnic),
                principal: String(this.terminalSelected.principalName),
                kitSerialNumber: String(this.terminalSelected.kitSerialNumber),
                siteId: String(this.terminalSelected.terminals_nickname),
                countryName: String(this.terminalSelected.countryName),
                accountNumber: String(this.terminalSelected.accountNumber),
                principalId: Number(this.terminalSelected.principalId),

            };

            console.log("Datos que se están enviando a la API para activar bypass:", payload);

            store.dispatch("MODEBYPASS", payload)
                .then((response) => {
                    console.log("Respuesta del servidor:", response);

                    const successMessage = this.$t("message.planta.m09");

                    store.dispatch("showToast", {
                        message: successMessage,
                        type: "success",
                    });
                    this.closePopup();
                })
                .catch((error) => {
                    console.error("Error en la respuesta:", error);

                    const errorMessage = this.$t("message.planta.m10");

                    store.dispatch("showToast", {
                        message: errorMessage,
                        type: "error",
                    });
                });
        }


    },
});
</script>
<style lang="scss">
.dropdown-content {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: 0px;
}

.dropdown-content:last-of-type {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: -225px;
    margin-left: 45px;
}

.dropdown-content:hover {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
}

.dropdown-content a:hover {
    background-color: #0079ff;
}

.btn-submit-address {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.btn-submit-mapa {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
    background-color: white;
}

.custom-input {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 95%;
    cursor: pointer;
}

.header-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.poppup-body p {
    color: #ffffff;
}

.btn-content2 button {
    height: 40px;
    cursor: pointer;
    width: 9rem;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
}

.close-style2 {
    display: flex;
    justify-content: end;
}
</style>